// app-routing.module.ts
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  /*{
    path: 'devices',
    loadChildren: () => import('./pages/device/devices/devices.module').then( m => m.DevicesPageModule)
  },
  {
    path: 'deviceslist',
    loadChildren: () => import('./pages/device/deviceslist/deviceslist.module').then( m => m.DeviceslistPageModule)
  },*/
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/users/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'usermanage',
    loadChildren: () => import('./pages/users/usermanage/usermanage.module').then( m => m.UsermanagePageModule)
  },
  {
    path: 'clientmanage',
    loadChildren: () => import('./pages/clients/clientmanage/clientmanage.module').then( m => m.ClientmanagePageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./pages/clients/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'dealer',
    loadChildren: () => import('./pages/dealers/dealer/dealer.module').then( m => m.DealerPageModule)
  },
  {
    path: 'dealermanage',
    loadChildren: () => import('./pages/dealers/dealermanage/dealermanage.module').then( m => m.DealermanagePageModule)
  },
  {
    path: 'companyinfo',
    loadChildren: () => import('./pages/company/companyinfo/companyinfo.module').then( m => m.CompanyinfoPageModule)
  },
  {
    path: 'shipment',
    loadChildren: () => import('./pages/shipments/shipment/shipment.module').then( m => m.ShipmentPageModule)
  },
  {
    path: 'shipmentconfig',
    loadChildren: () => import('./pages/shipments/shipmentconfig/shipmentconfig.module').then( m => m.ShipmentconfigPageModule)
  },
  {
    path: 'shipmentconfigmanage',
    loadChildren: () => import('./pages/shipments/shipmentconfigmanage/shipmentconfigmanage.module').then( m => m.ShipmentconfigmanagePageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/appmanage/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'ship',
    loadChildren: () => import('./pages/appmanage/ship/ship.module').then( m => m.ShipPageModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stocks/stock/stock.module').then( m => m.StockPageModule)
  },
  {
    path: 'stockmanage',
    loadChildren: () => import('./pages/stocks/stockmanage/stockmanage.module').then( m => m.StockmanagePageModule)
  },
  {
    path: 'stocklocation',
    loadChildren: () => import('./pages/stocks/stocklocation/stocklocation.module').then( m => m.StocklocationPageModule)
  },
  {
    path: 'stocklocationmanage',
    loadChildren: () => import('./pages/stocks/stocklocationmanage/stocklocationmanage.module').then( m => m.StocklocationmanagePageModule)
  },

 
  {
    path: 'warehouse',
    loadChildren: () => import('./pages/stocks/warehouse/warehouse.module').then( m => m.WarehousePageModule)
  },
  {
    path: 'warehousemanage',
    loadChildren: () => import('./pages/stocks/warehousemanage/warehousemanage.module').then( m => m.WarehousemanagePageModule)
  },
  {
    path: 'apphome',
    loadChildren: () => import('./pages/appmanage/apphome/apphome.module').then( m => m.ApphomePageModule)
  },
  {
    path: 'appbanners',
    loadChildren: () => import('./pages/appmanage/appbanners/appbanners.module').then( m => m.AppbannersPageModule)
  },
  {
    path: 'appheadmsg',
    loadChildren: () => import('./pages/appmanage/appheadmsg/appheadmsg.module').then( m => m.AppheadmsgPageModule)
  },
  {
    path: 'apparticle',
    loadChildren: () => import('./pages/appmanage/apparticle/apparticle.module').then( m => m.ApparticlePageModule)
  },
  {
    path: 'apparticlemanage',
    loadChildren: () => import('./pages/appmanage/apparticlemanage/apparticlemanage.module').then( m => m.ApparticlemanagePageModule)
  },
  {
    path: 'appbannersmanage',
    loadChildren: () => import('./pages/appmanage/appbannersmanage/appbannersmanage.module').then( m => m.AppbannersmanagePageModule)
  },
  {
    path: 'promotionmanage',
    loadChildren: () => import('./pages/promotions/promotionmanage/promotionmanage.module').then( m => m.PromotionmanagePageModule)
  },
  {
    path: 'promotion',
    loadChildren: () => import('./pages/promotions/promotion/promotion.module').then( m => m.PromotionPageModule)
  },
  {
    path: 'voucher',
    loadChildren: () => import('./pages/vouchers/voucher/voucher.module').then( m => m.VoucherPageModule)
  },
  {
    path: 'vouchermanage',
    loadChildren: () => import('./pages/vouchers/vouchermanage/vouchermanage.module').then( m => m.VouchermanagePageModule)
  },
  {
    path: 'salesorder',
    loadChildren: () => import('./pages/sales/salesorder/salesorder.module').then( m => m.SalesorderPageModule)
  },
  {
    path: 'salesordermanage',
    loadChildren: () => import('./pages/sales/salesordermanage/salesordermanage.module').then( m => m.SalesordermanagePageModule)
  },
  {
    path: 'opensales',
    loadChildren: () => import('./pages/sales/opensales/opensales.module').then( m => m.OpensalesPageModule)
  },
  {
    path: 'closesales',
    loadChildren: () => import('./pages/sales/closesales/closesales.module').then( m => m.ClosesalesPageModule)
  },
  
  {
    path: 'salesreport',
    loadChildren: () => import('./pages/reports/salesreport/salesreport.module').then( m => m.SalesreportPageModule)
  },
  {
    path: 'appship',
    loadChildren: () => import('./pages/appmanage/appship/appship.module').then( m => m.AppshipPageModule)
  },
  {
    path: 'appshipmanage',
    loadChildren: () => import('./pages/appmanage/appshipmanage/appshipmanage.module').then( m => m.AppshipmanagePageModule)
  },
  {
    path: 'point',
    loadChildren: () => import('./pages/points/point/point.module').then( m => m.PointPageModule)
  },
  {
    path: 'deliveryoption',
    loadChildren: () => import('./pages/shipments/deliveryoption/deliveryoption.module').then( m => m.DeliveryoptionPageModule)
  },
  {
    path: 'deliveryoptionmanage',
    loadChildren: () => import('./pages/shipments/deliveryoptionmanage/deliveryoptionmanage.module').then( m => m.DeliveryoptionmanagePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notifications/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'notificationmanage',
    loadChildren: () => import('./pages/notifications/notificationmanage/notificationmanage.module').then( m => m.NotificationmanagePageModule)
  },
  {
    path: 'salesbyvoucher',
    loadChildren: () => import('./pages/sales/salesbyvoucher/salesbyvoucher.module').then( m => m.SalesbyvoucherPageModule)
  },
  
  
  {
    path: 'customdesignview',
    loadChildren: () => import('./pages/wishlist/customdesignview/customdesignview.module').then( m => m.CustomdesignviewPageModule)
  },
  {
    path: 'customdesign',
    loadChildren: () => import('./pages/wishlist/customdesign/customdesign.module').then( m => m.CustomdesignPageModule)
  },
  {
    path: 'venue',
    loadChildren: () => import('./pages/venues/venue/venue.module').then( m => m.VenuePageModule)
  },
  {
    path: 'venuemanage',
    loadChildren: () => import('./pages/venues/venuemanage/venuemanage.module').then( m => m.VenuemanagePageModule)
  },
  {
    path: 'package',
    loadChildren: () => import('./pages/packages/package/package.module').then( m => m.PackagePageModule)
  },
  {
    path: 'packagemanage',
    loadChildren: () => import('./pages/packages/packagemanage/packagemanage.module').then( m => m.PackagemanagePageModule)
  },
  {
    path: 'class',
    loadChildren: () => import('./pages/class/class/class.module').then( m => m.ClassPageModule)
  },
  {
    path: 'classmanage',
    loadChildren: () => import('./pages/class/classmanage/classmanage.module').then( m => m.ClassmanagePageModule)
  },
  {
    path: 'classtype',
    loadChildren: () => import('./pages/class/classtype/classtype.module').then( m => m.ClasstypePageModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./pages/venues/branch/branch.module').then( m => m.BranchPageModule)
  },
  {
    path: 'branchmanage',
    loadChildren: () => import('./pages/venues/branchmanage/branchmanage.module').then( m => m.BranchmanagePageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedules/schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'schedulemanage',
    loadChildren: () => import('./pages/schedules/schedulemanage/schedulemanage.module').then( m => m.SchedulemanagePageModule)
  },
  {
    path: 'book',
    loadChildren: () => import('./pages/books/book/book.module').then( m => m.BookPageModule)
  },
  {
    path: 'bookmanage',
    loadChildren: () => import('./pages/books/bookmanage/bookmanage.module').then( m => m.BookmanagePageModule)
  },
  {
    path: 'todaybook',
    loadChildren: () => import('./pages/books/todaybook/todaybook.module').then( m => m.TodaybookPageModule)
  },
  {
    path: 'todaybookmanage',
    loadChildren: () => import('./pages/books/todaybookmanage/todaybookmanage.module').then( m => m.TodaybookmanagePageModule)
  },
  {
    path: 'topup',
    loadChildren: () => import('./pages/topups/topup/topup.module').then( m => m.TopupPageModule)
  },
  {
    path: 'topupmanage',
    loadChildren: () => import('./pages/topups/topupmanage/topupmanage.module').then( m => m.TopupmanagePageModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/clients/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'weekly',
    loadChildren: () => import('./pages/books/weekly/weekly.module').then( m => m.WeeklyPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./pages/clients/booking/booking.module').then( m => m.BookingPageModule)
  },
  {
    path: 'calendarmanage',
    loadChildren: () => import('./pages/schedules/calendarmanage/calendarmanage.module').then( m => m.CalendarmanagePageModule)
  },
  {
    path: 'bookingreport',
    loadChildren: () => import('./pages/reports/bookingreport/bookingreport.module').then( m => m.BookingreportPageModule)
  },
  {
    path: 'topupreport',
    loadChildren: () => import('./pages/reports/topupreport/topupreport.module').then( m => m.TopupreportPageModule)
  },
  {
    path: 'trainerreport',
    loadChildren: () => import('./pages/reports/trainerreport/trainerreport.module').then( m => m.TrainerreportPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
