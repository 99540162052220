// app.component.ts
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { GlobalfunctionService } from './services/globalfunction.service';
import { EventsService } from './services/events.service';
import { DatashareService } from './services/datashare.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  currentPageTitle = 'Dashboard';

  appPages = [];
  menu_user = "User";
  menu_access = "2";
  menu_info = "1";
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public global:GlobalfunctionService,
    public events:EventsService,
    private statusBar: StatusBar,
    private datashare:DatashareService
  ) {
    this.initializeApp();
    this.menu_user = this.global.getWith("auth_user","name");
    this.menu_access = this.global.getWith("auth_user","access");
    this.menu_info = this.global.getWith("auth_user","dinfo");
    this.datashare.isUserLoggedIn.subscribe( value => {
    if(value["access"] != "" || value["access"] != null)
    {
      this.user_page(value["access"],value["dinfo"]);
    }
    else
    {
     
      this.user_page(localStorage.getItem("loginstat"),localStorage.getItem("logindinfo"));
    }
  });
  
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    
  }
  ngOnInit() {
    if(localStorage.getItem("loginstat") != "" || localStorage.getItem("loginstat") != null)
      this.user_page(localStorage.getItem("loginstat"),localStorage.getItem("logindinfo"));
  }

  user_page(level,dinfo)
  {
    if(dinfo == "1")
    {
      this.ecommerce(level)
    }
    else if(dinfo == "2")
    {
      this.booking(level)
    }
    
  }

  ecommerce(level)
  {
    if(level == "1")
    {
        this.appPages = [
          {
            title: 'Dashboard',
            url: './dashboard',
            icon: 'home'
          },
          {
            title: 'Products',
            url: '',
            icon: 'cube',
            child:[
             
              {
                title: 'Product Suppliers',
                url: '/productsupplier',
                icon: 'storefront',
              },
              {
                title: 'Product Categories',
                url: '/productcategory',
                icon: 'grid',
              },
              {
                title: 'Products',
                url: '/product',
                icon: 'layers',
              }
            ]
          },
          {
            title: 'Stock',
            url: '',
            icon: 'library',
            child:[
              {
                title: 'Warehouse',
                url: '/warehouse',
                icon: 'business',
              },
              {
                title: 'Stock Location',
                url: '/stocklocation',
                icon: 'location',
              },
              {
                title: 'Stock',
                url: '/stock',
                icon: 'grid',
              }
            ]
          },
          {
            title: 'Sales',
            url: '',
            icon: 'bar-chart',
            child:[
              {
                title: 'Sales Order',
                url: '/salesorder',
                icon: 'list',
              },
              {
                title: 'Open Sales',
                url: '/opensales',
                icon: 'list',
              },
              {
                title: 'Closed Sales',
                url: '/closesales',
                icon: 'list',
              }
            ]
          },
          {
            title: 'Report',
            url: '',
            icon: 'list',
            child:[
              /*{
                title: 'Promotion',
                url: '/promotion',
                icon: 'cart',
              },*/
              {
                title: 'Sales Report',
                url: '/salesreport',
                icon: 'list',
              }
            ]
          },
          {
            title: 'Marketing',
            url: '',
            icon: 'pricetags',
            child:[
              /*{
                title: 'Promotion',
                url: '/promotion',
                icon: 'cart',
              },*/
              {
                title: 'Notification',
                url: '/notification',
                icon: 'notifications',
              },
              {
                title: 'Voucher',
                url: '/voucher',
                icon: 'grid',
              },
              {
                title: 'Points',
                url: '/point',
                icon: 'grid',
              }
            ]
          },
          {
            title: 'Custom Design',
            url: './customdesign',
            icon: 'search'
          },
          {
            title: 'Client Management',
            url: '',
            icon: 'people',
            child:[
              {
                title: 'Client',
                url: '/client',
                icon: 'people'
              },
              {
                title: 'Dealer',
                url: '/dealer',
                icon: 'storefront'
              }
              /*,
              {
                title: 'Test',
                url: '',
                icon: 'storefront',
                child:[
                  {
                    title: 'Gateway Lists',
                    url: '/dealer',
                    icon: 'list',
                  },
                ]
              }*/
            ]
          },
          {
            title: 'Shipment',
            url: '',
            icon: 'car',
            child:[
              {
                title: 'Shipment Cost',
                url: '/appship',
                icon: 'construct'
              },
              {
                title: 'Delivery Options',
                url: '/deliveryoption',
                icon: 'car'
              }
              /*,
              {
                title: 'Test',
                url: '',
                icon: 'storefront',
                child:[
                  {
                    title: 'Gateway Lists',
                    url: '/dealer',
                    icon: 'list',
                  },
                ]
              }*/
            ]
          },
          {
            title: 'System Management',
            url: '',
            icon: 'file-tray-full',
            child:[
              {
                title: 'Users',
                url: '/user',
                icon: 'people'
              },
              {
                title: 'Company Information',
                url: '/companyinfo',
                icon: 'storefront'
              },
              {
                title: 'App Management',
                url: '',
                icon: 'phone-portrait',
                child:[
                  /*{
                    title: 'App Home Page',
                    url: '/apphome',
                    icon: 'home'
                  },*/
                  {
                    title: 'Announcement',
                    url: '/appheadmsg',
                    icon: 'megaphone'
                  },
                  {
                    title: 'Banners',
                    url: '/appbanners',
                    icon: 'image'
                  },
                  {
                    title: 'Articles',
                    url: '/apparticle',
                    icon: 'document-text'
                  },
                  {
                    title: 'Shipping & Tracking',
                    url: '/terms',
                    icon: 'construct'
                  },
                  {
                    title: 'Returns & Exchanges',
                    url: '/ship',
                    icon: 'car'
                  }
                  /*,
                  {
                    title: 'Shipping Cost',
                    url: '/appship',
                    icon: 'cash'
                  }
                  */
                  /*,
                  {
                    title: 'Test',
                    url: '',
                    icon: 'storefront',
                    child:[
                      {
                        title: 'Gateway Lists',
                        url: '/dealer',
                        icon: 'list',
                      },
                    ]
                  }*/
                ]
              },
              {
                title: 'Configuration',
                url: '/projects',
                icon: 'construct'
              }
              
            ]
          },
         
          {
            title: 'Logout',
            url: '/logout',
            icon: 'exit'
          }
          
        ];
    }
    else if(level == "2")
    {
      this.appPages = [
        {
          title: 'Dashboard',
          url: './dashboard',
          icon: 'home'
        },
        {
          title: 'Products',
          url: '',
          icon: 'cube',
          child:[
           
            {
              title: 'Product Suppliers',
              url: '/productsupplier',
              icon: 'storefront',
            },
            {
              title: 'Product Categories',
              url: '/productcategory',
              icon: 'grid',
            },
            {
              title: 'Products',
              url: '/product',
              icon: 'layers',
            }
          ]
        },
        {
          title: 'Stock',
          url: '',
          icon: 'library',
          child:[
            {
              title: 'Warehouse',
              url: '/warehouse',
              icon: 'business',
            },
            {
              title: 'Stock Location',
              url: '/stocklocation',
              icon: 'location',
            },
            {
              title: 'Stock',
              url: '/stock',
              icon: 'grid',
            }
          ]
        },
        {
          title: 'Sales',
          url: '',
          icon: 'bar-chart',
          child:[
            {
              title: 'Sales Order',
              url: '/salesorder',
              icon: 'list',
            },
            {
              title: 'Open Sales',
              url: '/opensales',
              icon: 'list',
            },
            {
              title: 'Closed Sales',
              url: '/closesales',
              icon: 'list',
            }
          ]
        },
        {
          title: 'Report',
          url: '',
          icon: 'list',
          child:[
            /*{
              title: 'Promotion',
              url: '/promotion',
              icon: 'cart',
            },*/
            {
              title: 'Sales Report',
              url: '/salesreport',
              icon: 'list',
            }
          ]
        },
        {
          title: 'Marketing',
          url: '',
          icon: 'pricetags',
          child:[
            /*{
              title: 'Promotion',
              url: '/promotion',
              icon: 'cart',
            },*/
            {
              title: 'Notification',
              url: '/notification',
              icon: 'notifications',
            },
            {
              title: 'Voucher',
              url: '/voucher',
              icon: 'grid',
            }
          ]
        },
        {
          title: 'Custom Design',
          url: './customdesign',
          icon: 'search'
        },
        {
          title: 'Client Management',
          url: '',
          icon: 'people',
          child:[
            {
              title: 'Client',
              url: '/client',
              icon: 'people'
            },
            {
              title: 'Dealer',
              url: '/dealer',
              icon: 'storefront'
            }
            /*,
            {
              title: 'Test',
              url: '',
              icon: 'storefront',
              child:[
                {
                  title: 'Gateway Lists',
                  url: '/dealer',
                  icon: 'list',
                },
              ]
            }*/
          ]
        },
        
       
        {
          title: 'Logout',
          url: '/logout',
          icon: 'exit'
        }
        
      ];
  
    }
    else if(level == "3")
    {
      this.appPages = [
        {
          title: 'Dashboard',
          url: './dashboard',
          icon: 'home'
        },
        {
          title: 'Sales',
          url: '',
          icon: 'bar-chart',
          child:[
            {
              title: 'Sales Order',
              url: '/salesorder',
              icon: 'list',
            },
            {
              title: 'Open Sales',
              url: '/opensales',
              icon: 'list',
            },
            {
              title: 'Closed Sales',
              url: '/closesales',
              icon: 'list',
            }
          ]
        },
        {
          title: 'Report',
          url: '',
          icon: 'list',
          child:[
            /*{
              title: 'Promotion',
              url: '/promotion',
              icon: 'cart',
            },*/
            {
              title: 'Sales Report',
              url: '/salesreport',
              icon: 'list',
            }
          ]
        },
        {
          title: 'Logout',
          url: '/logout',
          icon: 'exit'
        }
        
      ];
  
    }
  }

  booking(level)
  {
    if(level == "1")
    {
        this.appPages = [
          {
            title: 'Dashboard',
            url: './dashboard',
            icon: 'home'
          },
          {
            title: 'Class',
            url: '/class',
            icon: 'layers'
          },
          {
            title: 'Venues',
            url: '',
            icon: 'storefront',
            child:[
              {
                title: 'Branch',
                url: '/branch',
                icon: 'storefront',
              },
              {
                title: 'Venue',
                url: '/venue',
                icon: 'location',
              }
            ]
          },
          {
            title: 'Schedule',
            url: '/schedule',
            icon: 'calendar'
          },
          {
            title: 'Booking',
            url: '/book',
            icon: 'today'/*,
            child:[
              {
                title: 'Booking',
                url: '/book',
                icon: 'list',
              }
            ]*/
          },
          {
            title: 'Topup',
            url: '/topup',
            icon: 'add-circle'
          },
          {
            title: 'Packages',
            url: '/package',
            icon: 'cash'
          },
          {
            title: 'Report',
            url: '',
            icon: 'list',
            child:[
              {
                title: 'Booking Report',
                url: '/bookingreport',
                icon: 'list',
              },
              {
                title: 'Topup Report',
                url: '/topupreport',
                icon: 'list',
              },
              {
                title: 'Trainer Report',
                url: '/trainerreport',
                icon: 'list',
              }
            ]
          },
          
          {
            title: 'Students',
            url: '',
            icon: 'people',
            child:[
              {
                title: 'Student',
                url: '/client',
                icon: 'people'
              }
             
            ]
          },
          {
            title: 'Point',
            url: '/point',
            icon: 'wallet',
          },
          {
            title: 'Users',
            url: '/user',
            icon: 'people',
          },
          {
            title: 'Company Information',
            url: '/companyinfo',
            icon: 'storefront',
          },
         
          {
            title: 'Logout',
            url: '/logout',
            icon: 'exit'
          }
          
        ];
    }
    else if(level == "2")
    {
      this.appPages = [
        {
          title: 'Dashboard',
          url: './dashboard',
          icon: 'home'
        },
        {
          title: 'Class',
          url: '/class',
          icon: 'layers'
        },
        {
          title: 'Venues',
          url: '',
          icon: 'storefront',
          child:[
            {
              title: 'Branch',
              url: '/branch',
              icon: 'storefront',
            },
            {
              title: 'Venue',
              url: '/venue',
              icon: 'location',
            }
          ]
        },
        {
          title: 'Schedule',
          url: '/schedule',
          icon: 'calendar'
        },
       
        {
          title: 'Booking',
          url: '/book',
          icon: 'today'/*,
          child:[
            {
              title: 'Booking',
              url: '/book',
              icon: 'list',
            }
          ]*/
        },
        {
          title: 'Topup',
          url: '/topup',
          icon: 'add-circle'
        },
        {
          title: 'Report',
          url: '',
          icon: 'list',
          child:[
            {
              title: 'Booking Report',
              url: '/bookingreport',
              icon: 'list',
            },
            {
              title: 'Topup Report',
              url: '/topupreport',
              icon: 'list',
            },
            {
              title: 'Trainer Report',
              url: '/trainerreport',
              icon: 'list',
            }
          ]
        },
        
        {
          title: 'Students',
          url: '',
          icon: 'people',
          child:[
            {
              title: 'Student',
              url: '/client',
              icon: 'people'
            }
           
          ]
        },
        {
          title: 'Users',
          url: '/user',
          icon: 'people',
        },
       
        {
          title: 'Logout',
          url: '/logout',
          icon: 'exit'
        }
        
      ];
  
    }
    else if(level == "3")
    {
      this.appPages = [
        {
          title: 'Booking',
          url: '/book',
          icon: 'today'/*,
          child:[
            {
              title: 'Booking',
              url: '/book',
              icon: 'list',
            }
          ]*/
        },
        {
          title: 'Class',
          url: '/class',
          icon: 'layers'
        },
        {
          title: 'My Report',
          url: '',
          icon: 'list',
          child:[
            {
              title: 'My Report',
              url: '/trainerreport',
              icon: 'list',
            }
          ]
        },
        {
          title: 'Schedule',
          url: '/schedule',
          icon: 'calendar'
        },
        
        {
          title: 'Logout',
          url: '/logout',
          icon: 'exit'
        }
        
      ];
  
    }
  }
}
