import { Injectable } from '@angular/core';
import { MenuController,AlertController,LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventsService } from '../services/events.service';
@Injectable({
  providedIn: 'root'
})
export class GlobalfunctionService {
  
  constructor(private menu:MenuController,private http: HttpClient,private router:Router,private alert:AlertController,public loadingCtrl: LoadingController, public events:EventsService)  {}

  base_path = "https://flying-kitty.com";
  //loading: any;
  initialize()
  {
    this.menu.enable(true, 'custom');
    //this.menu_user = this.getWithName("auth_user");
    if(this.getWithExpiry("auth_user") == null)
    {
      //this.router.navigate(['/login']);
      this.tokenAlert();
    }
  }


  /************************************************Requesting API********************************************************/
  //MOICW
  //Request API from base_path
  /**********************************************************************************************************************/
  
  /*************** Http Options Headers ***************/
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 4f2bdcce37ac065a507a5fc9d5fcb92d50e4334a8eb89b25c8d71e4441e14614'
    })
  }
  httpOptions_nobearer = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  /*************** API Handle Error ***************/
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  
   /*************** Data Insertion ***************/
  insert(table,data){
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
    return this.http
      .post(this.base_path+"/api/functions/tinsert.php?tbl="+table, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  insertdata(table,data){
   
    return new Promise((resolve, reject) => {
     
      this.insert(table,data)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
      
  }
  /*************** Data Read ***************/
  read(data,table,param,page) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+"/api/functions/tread.php?tbl="+table+"&param="+param+"&page="+page, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  
  readdata(data,table,param,page) {
    return new Promise((resolve, reject) => {
     
      this.read(data,table,param,page)
        .subscribe(res => {
          console.log(res);
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
  }

  /*************** Data Read Delete ***************/
  read_delete(data,table,param,page) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+"/api/functions/tread_isdelete.php?tbl="+table+"&param="+param+"&page="+page, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  
  read_deletedata(data,table,param,page) {
    return new Promise((resolve, reject) => {
     
      this.read_delete(data,table,param,page)
        .subscribe(res => {
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
  }
  /*************** Data Read No Token***************/
  readnot(data,table,param,page) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+"/api/functions/read.php?tbl="+table+"&param="+param+"&page="+page, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  
  readdatanot(data,table,param,page) {
    return new Promise((resolve, reject) => {
     
      this.readnot(data,table,param,page)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
    
  }

   /*************** Data Read No Token***************/
   readjson(json_name) {
   
      return this.http
      .post(this.base_path+"/api/json/"+json_name+".php", null, this.httpOptions_nobearer)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
   
  }
  
  readdatajson(json_name) {
    return new Promise((resolve, reject) => {
     
      this.readjson(json_name)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
    
  }
/*************** Data Read No Token***************/
uploadbase64not(data) {
  
 
    return this.http
    .post(this.base_path+"/api/functions/upload.php?param=3", data, this.httpOptions_nobearer)
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
  
}

uploadbase64datanot(data) {
  return new Promise((resolve, reject) => {
   
    this.uploadbase64not(data)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });
  
}

/*************** Data Read No Token***************/
uploadbase64not_one(data) {
  
 
  return this.http
  .post(this.base_path+"/api/functions/upload.php?param=2", data, this.httpOptions_nobearer)
  .pipe(
    retry(2),
    catchError(this.handleError)
  );

}

uploadbase64datanot_one(data) {
return new Promise((resolve, reject) => {
 
  this.uploadbase64not_one(data)
    .subscribe(res => {
      resolve(res);
    }, (err) => {
      reject(err);
    });
});

}

  //Set Vendor Api Request
  vendorapirequest(data) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+"/api/functions/vendor_api.php", data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  vendorapi(data) {
    return new Promise((resolve, reject) => {
     
      this.vendorapirequest(data)
        .subscribe(res => {
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
  }

  
 /*************** Update Data ***************/
  update(table,refer_column,refer_val, data) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+'/api/functions/tupdate.php?tbl='+table+'&item='+refer_column+'&val='+refer_val, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  updatedata(table,refer_column,refer_val, data) {
    return new Promise((resolve, reject) => {
     
      this.update(table,refer_column,refer_val, data)
        .subscribe(res => {
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
   
  }

  /*************** Update Data ***************/
  delete(table,refer_column,refer_val, data) {
    data.session_token = this.getWithToken("auth_user");

    if(data.session_token != "")
    {
      return this.http
      .post(this.base_path+'/api/functions/tdelete.php?tbl='+table+'&item='+refer_column+'&val='+refer_val, JSON.stringify(data), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  deletedata(table,refer_column,refer_val, data) {
    return new Promise((resolve, reject) => {
    
      this.delete(table,refer_column,refer_val, data)
        .subscribe(res => {
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
  
  }

  /*************** Update Data ***************/
  sendpush(id) {
    let session_token = this.getWithToken("auth_user");

    if(session_token != "")
    {
      return this.http
      .post(this.base_path+'/api/functions/push_send.php?id='+id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
    }
    else
    {
      this.tokenAlert();
    }
  }
  sendpushdata(id) {
    return new Promise((resolve, reject) => {
    
      this.sendpush(id)
        .subscribe(res => {
          if(res["Message"] == "Token Expired")
          {
            this.tokenAlert();
          }
          else
          {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
    
  
  }
  /**read dashboard */
   /*************** Data Read No Token***************/
   readdashboard() {  
   
    return this.http
    .post(this.base_path+"/api/functions/dashboard.php", null, this.httpOptions_nobearer)
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
 
  }

  readdatadashboard() {
    return new Promise((resolve, reject) => {
    
      this.readdashboard()
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
    
  }


  /*** CUSTOMIZE ***/
  /*************** Unit Request ***************/
  unit_request(data) {
      
    return this.http
    .post(this.base_path+"/api/functions/unitrequest.php", data, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
  }
  /*************** Validate User ***************/
  validate_user(data) {
    
    return this.http
    .post(this.base_path+"/api/functions/validate.php", data, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
  }
 
  /*************** JSON Format Setup ***************/
  sort(sortby,sorttype)
  {
    if(sortby == "No" && sorttype == "No")
    { 
        let format = {Format:"No"};
        return format;
    }
    else
    {
        let format = {Format:{Sort:{SortBy:"",SortType:""}}};
        format.Format.Sort.SortBy = sortby;
        format.Format.Sort.SortType = sorttype;
        return format;
    }
  }

  
  /************ format ****************/
  search(searchval,searchby,searchtbl)
  {
    if(searchval == "No" && searchby == "No" && searchtbl == "No")
    { 
        let search = {Search:"No"};
        return search;
    }
    else if(searchby == "All" && searchtbl == "All")
    {
        let k = 0;
        
        
        
        let search = {Search:{Item:{Search:"",SearchBy:"All",SearchTbl:"All"}}};
        search.Search.Item.Search = searchval;
       
        return search;
    }
    else
    {
        let k = 0;
        
        let searchby_data=[];
        for(k=0;k<searchby.length;k++)
        {
          let searchby_array= {Key:""};
          searchby_array.Key = searchby[k];
          searchby_data.push(searchby_array);
          
        }
        let searchtbl_data=[];
        for(k=0;k<searchtbl.length;k++)
        {
          let searchtbl_array= {Key:""};
          searchtbl_array.Key = searchtbl[k];
          searchtbl_data.push(searchtbl_array);
          
        }
        
        let search = {Search:{Item:{Search:"",SearchBy:[],SearchTbl:[]}}};
        search.Search.Item.Search = searchval;
        search.Search.Item.SearchBy = searchby_data;
        search.Search.Item.SearchTbl = searchtbl_data;
        return search;
    }
  }
  range(start,end,column,table)
  {
    if(start == "No" && end == "No" && column == "No" && table == "No")
    { 
        let range = {Range:"No"};
        return range;
    }
    else
    {
        let range = {Range:{Item:{Start:"",End:"",Column:"",Table:""}}};
        range.Range.Item.Start = start;
        range.Range.Item.End = end;
        range.Range.Item.Column = column;
        range.Range.Item.Table = table;
        return range;
    }
  }
  requestitem(table,column,value,tblkey,fktbl,fkkey)
  {
    let item = "";
    let foreign = "";
    let k = 0;
    let table_data=[];
    let column_data=[];
    let value_data=[];
    let tblkey_data=[];
    let fktbl_data=[];
    let fkkey_data=[];
    
    if(table == "All" && column == "All" && value == "All")
    { 
        item = "All";
     
    }
    else
    {
        for(k=0;k<table.length;k++)
        {
          let table_array= {Tbl:""};
          table_array.Tbl = table[k];
          table_data.push(table_array);
          
        }

       
        for(k=0;k<column.length;k++)
        {
          let column_array= {Col:""};
          column_array.Col = column[k];
          column_data.push(column_array);
          
        }

        
        for(k=0;k<value.length;k++)
        {
          let value_array= {Val:""};
          value_array.Val = value[k];
          value_data.push(value_array);
          
        }
    }
  
    if(tblkey == "No" && fktbl == "No" && fkkey == "No")
    { 
      foreign = "No";
     
    }
    else
    {
        for(k=0;k<tblkey.length;k++)
        {
          let tblkey_array= {Key:""};
          tblkey_array.Key = tblkey[k];
          tblkey_data.push(tblkey_array);
          
        }

        
        for(k=0;k<fktbl.length;k++)
        {
          let fktbl_array= {Tbl:""};
          fktbl_array.Tbl = fktbl[k];
          fktbl_data.push(fktbl_array);
          
        }

        
        for(k=0;k<fkkey.length;k++)
        {
          let fkkey_array= {Key:""};
          fkkey_array.Key = fkkey[k];
          fkkey_data.push(fkkey_array);
          
        }
    }
    if(item == "All" && foreign !="No")
    {
        let request = {Request:{Item:"All",ForeignKey:{TblKey:[],FkTbl:[],FkKey:[]}}};
        
        request.Request.ForeignKey.TblKey = tblkey_data;
        request.Request.ForeignKey.FkTbl = fktbl_data;
        request.Request.ForeignKey.FkKey = fkkey_data;
        return request;
    }
    else if(item == "All" && foreign =="No")
    {
        let request = {Request:{Item:"All",ForeignKey:"No"}};
        
        return request;
    }
    else  if(item != "All" && foreign =="No")
    {
        let request = {Request:{Item:{Table:[],Column:[],Value:[]},ForeignKey:"No"}};
        request.Request.Item.Table = table_data;
        request.Request.Item.Column = column_data;
        request.Request.Item.Value = value_data;
      
        return request;
    }
    else
    {
        let request = {Request:{Item:{Table:[],Column:[],Value:[]},ForeignKey:{TblKey:[],FkTbl:[],FkKey:[]}}};
        request.Request.Item.Table = table_data;
        request.Request.Item.Column = column_data;
        request.Request.Item.Value = value_data;
        request.Request.ForeignKey.TblKey = tblkey_data;
        request.Request.ForeignKey.FkTbl = fktbl_data;
        request.Request.ForeignKey.FkKey = fkkey_data;
        return request;
    }
    
  }

  json_readdata(data,param)
  {
    let json_format={Format:""};
    let json_request={Request:""};
    let json_search={Search:""};
    let json_range={Range:""};
    if(param == "1")
    {
      Object.keys(data).forEach(function(key) {
        if(data.hasOwnProperty('Format'))
        {
          if(key == "Format")
          {
            json_format.Format = data[key];
          }
        }
        else
        {
            json_format.Format = "No";
        }
        if(data.hasOwnProperty('Search'))
        {
          if(key == "Search")
          {
            json_search.Search = data[key];
          }
        }
        else
        {
          json_search.Search = "No";
        }

        if(data.hasOwnProperty('Request'))
        {
          if(key == "Request")
          {
            json_request.Request = data[key];
          }
        }
        else
        {
          json_request.Request = "No";
        }
        if(data.hasOwnProperty('Range'))
        {
          if(key == "Range")
          {
            json_range.Range = data[key];
          }
        }
        else
        {
          json_range.Range = "No";
        }
        
      })
    
    }
   
    return Object.assign(json_format,json_request,json_search,json_range);
  }

  
 
  /************************************************Requesting API - END********************************************************/
  
  

  /************************************************Token Checking I********************************************************/
  //MOICW
  //Token
  /**********************************************************************************************************************/
  
  setWithExpiry(key, name,access, value, ttl,dinfo) {
    const now = new Date()
  
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    if(ttl == null)
    {
      const item = {
        name: name,
        access: access,
        value: value,
        dinfo:dinfo,
        expiry: null,
      }
      localStorage.setItem(key, JSON.stringify(item));
    }
    else
    {
      const item = {
        name: name,
        access: access,
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    }
    
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if(item.expiry == null)
    {
      return "noremember";
    }
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  getWithToken(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
   
    if(item.value == null)
    {
      return "noremember";
    }
    else
    {
      return item.value;
    }
  }
  /*getWithName(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
   
    if(item.name == null)
    {
      return "user";
    }
    else
    {
      return item.name;
    }
  }
  */
  getWith(key,type) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
   if(type == "name")
   {
      if(item.name == null)
      {
        return "user";
      }
      else
      {
        return item.name;
      }
    }
    else if(type == "access")
    {
      if(item.access == null)
      {
        return "2";
      }
      else
      {
        return item.access;
      }
    }
  }
  async tokenAlert() {  
    const alert = await this.alert.create({  
      header: 'Sorry, Token Expired!',  
      message: 'Please Re-login',  
      buttons: ['OK']  
    });  
    await alert.present();  
    
    localStorage.removeItem("auth_user");
    localStorage.removeItem("remember");
    this.router.navigate(['/login']);
    const result = await alert.onDidDismiss();  
  }  

  async showLoader(loader){
    loader = await this.loadingCtrl.create({
      message: 'Loading',
      duration:5000
    });
   

    loader.present();
  }
  async hideLoader(loader){
   
    await loader.dismiss();
  }
  private loading: HTMLIonLoadingElement;
  private isShowing = false;
  
  public async presentLoader(message: string): Promise<void> {
    if (!this.isShowing) {
        this.isShowing = true;
        this.loading = await this.loadingCtrl.create({
            message: message
        });
        return await this.loading.present();
    } else {
        // If loader is showing, only change text, won't create a new loader.
        this.isShowing = true;
        this.loading.message = message;
    }
}

public async dismissLoader(): Promise<void> {
    if (this.loading && this.isShowing) {
        this.isShowing = false;
        await this.loading.dismiss();
    }
}

public event_publish(name,val)
{
  //this.events.destroy(name);
  this.events.publish(name, {
      data:  val
  });
}

}
